.forum .background-image-category {
    width: 50px;
    height: 50px;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    margin: 0 auto;
    border-radius: 50%;
}
.forum .category-name {
    text-align: center;
    font-size: 11px;
    color: #000;
    font-weight: 600;
    padding-top: 5px;
    white-space: break-spaces;
    width: 100px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 15px;
    display: -webkit-box;
}

.forum .categories-wrapper {
    padding: 15px;
    border-radius: 8px;
    width: 100%;
	overflow-x: hidden;
	white-space: nowrap;
    /* background-color: #fff; */
}

.forum .custom-card {
    width: auto;
    display: inline-block;
    vertical-align: text-top;
    padding: 5px;
    margin-right: 10px;
    box-shadow: 0px 0px 10px -4px rgb(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 10px -4px rgb(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 10px -4px rgb(0,0,0,0.75);
    border-radius: 8px;
    height: 50px;
    background-color: #fff;
}

.forum .next-button-wrapper {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 99;
    height: 100%;
    width: 35px;
    background-color: rgba(0,0,0,0.3);
    border-radius: 0 8px 8px 0;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: 110%;
    cursor: pointer;
}

.forum .prev-button-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 99;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    border-radius: 8px 0 0 8px;
    width: 35px;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: 110%;
    cursor: pointer;
}

.forum .next-button-wrapper button,
.forum .prev-button-wrapper button {
    margin: 35px 0;
}

.forum .categories-heading {
    padding: 15px;
}

.forum .professional-wrapper {
    padding: 0;
}

.forum .professional-wrapper .professional-wrapper-row {
    box-shadow: 0px 0px 10px -4px rgb(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 10px -4px rgb(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 10px -4px rgb(0,0,0,0.75);
    padding: 15px;
    border-radius: 8px;
    cursor: pointer;
}

.forum .professional-name {
    font-weight: bold;
    color: #000;
    font-size: 14px;
    margin-bottom: 5px;
}

.forum .professional-designation {
    font-size: 14px;
    color: #000;
    font-weight: bold;
}

.forum .professional-designation img {
    float: right;
}

.forum .professional-designation span {
    margin-left: 10px;
    color: #aaa;
    font-size: 12px;
    font-weight: 200;
}

.forum .professional-location {
    font-size: 12px;
}

.forum .answer {
    font-weight: bold;
    font-size: 13px;
    padding: 15px;
}

.forum .answer-text {
    box-shadow: 0px 0px 10px -4px rgb(0 0 0 / 75%);
    -webkit-box-shadow: 0px 0px 10px -4px rgb(0 0 0 / 75%);
    -moz-box-shadow: 0px 0px 10px -4px rgb(0,0,0,0.75);
    padding: 15px;
    border-radius: 8px;
    margin: 10px 0;
    width: calc(100% - 80px);
    display: inline-block;
    font-size: 12px;
}

.forum .answer-text .answer-date {
    color: #aaa;
    font-size: 12px;
    font-weight: 200;
    float: right;
}

.forum .professional-exp {
    font-size: 11px;
    margin-top: 5px;
}

.forum .professional-exp span {
    float: right;
}

.forum .professional-exp img {
    margin-right: 3px;
    vertical-align: text-top;
    width: 14px;
}

.forum .background-image-professional,
.forum .background-image-premium {
    width: 50px;
    height: 50px;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    margin: 10px auto;
    border-radius: 50%;
    display: inline-block;
}

.forum .whole-categories-wrapper {
    position: relative;
}

.forum .sort-down {
    position: absolute;
    top: 20px;
    width: 20px;
    right: 25px;
    cursor: pointer;
}

.forum .language-dropdown-wrapper {
    padding: 8px 15px 15px;
}

.forum .language-dropdown-wrapper .btn-link {
    text-decoration: underline;
}

.forum .language-dropdown-wrapper .btn-link:hover {
    background-color: transparent;
    color: #5867dd;
}
.forum .language-dropdown-wrapper .dropdown {
    display: inline-block;
    margin-left: 10px;
}

.forum .dropdown-menu {
    right: 0;
}

.forum .professional-near-you-wrapper {
    padding: 15px;
    box-shadow: 0px 0px 10px -4px rgb(0 0 0 / 75%);
    -webkit-box-shadow: 0px 0px 10px -4px rgb(0 0 0 / 75%);
    -moz-box-shadow: 0px 0px 10px -4px rgb(0,0,0,0.75);
    background-color: #fff;
    border-radius: 8px;
}

.forum .answer-commentedby {
    color: #000;
    font-size: 14px;
    margin-bottom: 10px;
}

.forum .show-answers-bottom {
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.forum .message-input {
    margin-top: 10px;
    width: calc(100% - 80px);
    display: inline-block;
    padding: 7px 0;
}

.forum .message-input input {
    border-color: #ccc;
}

.forum .message-input button {
    border: none;
    padding-top: 5px;
}

.forum .message-input button:hover {
    border: none;
    background-color: transparent;
}

.forum .answers-wrapper {
    max-height: 300px;
    overflow: auto;
    padding-right: 10px;
}

.forum .qa-by-you {
    color: #000;
    font-size: 12px;
}

.forum .qa-date {
    color: #aaa;
    font-size: 12px;
}

.forum .qa-status {
    color: #185BDB;
    font-size: 12px;
    padding-top: 10px;
}

.arrow-left {
    width: 0; 
    height: 0; 
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent; 
    
    border-right:10px solid blue; 
  }


.ask-question-modal .login-logo-wrapper {
    text-align: center;
}

.ask-question-modal .welcome-text {
    color: #000;
    font-weight: 600;
    font-size: 16px;
}

.ask-question-modal .sign-in-text {
    color: #aaa;
    font-size: 12px;
}

.ask-question-modal .login-button {
    padding: 25px 0;
    text-align: right;
}

.ask-question-modal .login-button button {
    width: 100%;
    margin-bottom: 5px;
    font-size: 14px;
}
.ask-question-modal .login-button a {
    font-size: 12px;
    color: #185BDB !important;
    text-decoration: underline !important;
}
.ask-question-modal .login-phone-input {
    padding: 35px 10px 0;
}

.ask-question-modal .login-phone-input .input-group {
    border: 1px solid #ccc;
    border-radius: 4px;
}

.ask-question-modal .login-phone-input .input-group-text {
    color: #000;
}

.ask-question-modal .accept-checkbox-wrapper {
    font-size: 11px;
    color: #242323;
}

.ask-question-modal .accept-checkbox-wrapper .form-check-label {
    padding-left: 5px;
}

.ask-question-modal .accept-checkbox-wrapper a {
    color: #185BDB !important;
}

.ask-question-modal .modal-body {
    padding: 30px 15px;
}

.ask-question-modal .login-otp-input-wrapper .form-control {
    border: 1px solid #ccc;
    text-align: center;
    font-size: 16px;
    color: #185BDB;
    font-weight: 600;
}

.ask-question-modal .cancel-otp-page {
    float: left;
}

.ask-question-modal .question-info {
    margin-bottom: 15px;
    font-size: 13px;
}

.ask-question-modal .question-input label {
    color: #000;
    font-size: 16px;
}

.ask-question-modal .modal-body {
    padding: 15px;
}
 
.ask-question-modal .question-info i {
    margin-right: 10px;
}

.ask-question-modal .modal-title {
    font-size: 18px !important;
}


.forum .question-asked-details {
    padding: 10px;
    width: calc(100% - 100px);
    display: inline-block;
    vertical-align: top;
}

.forum .question-commentedby {
    color: #1B3467;
    font-size: 18px;
}

@media only screen and (min-width: 768px) {
    .forum .forum-more-768-pr-15 {
        padding-right: 15px;
    }
    .forum .forum-more-768-pb-15 {
        padding-bottom: 15px;
    }
}
@media only screen and (max-width: 767px) {
    .forum .forum-less-768-pb-15 {
        padding-bottom: 15px;
    }
    .forum .professional-near-you-wrapper {
        padding: 10px;
    }
    .forum .answer {
        padding: 10px;
    }
    .forum .background-image-professional, .forum .background-image-premium {
        width: 40px;
        height: 40px;
    }
    .forum .answer-text {
        width: calc(100% - 60px);
    }
}