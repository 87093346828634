* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  font-family: Helvetica, Arial, sans-serif;
  color: #333e5a;
  min-height: 100%;
}

.video-call-wrapper header {
  background: #f0293e;
  color: #fff;
  text-align: center;
  flex-grow: 0;
  margin-bottom: 2em;
}

.video-call-wrapper h1 {
  font-weight: 300;
  padding: 0.4em 0;
}

.video-call-wrapper #root {
  min-height: 100vh;
}

.video-call-wrapper main {
  background: #ffffff;
  flex-grow: 1;
}

.video-call-wrapper .no-participant-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-call-wrapper form {
  max-width: 300px;
  margin: 0 auto;
}

.video-call-wrapper h2 {
  font-weight: 300;
  margin-bottom: 1em;
  text-align: center;
}

.video-call-wrapperform > div {
  width: 100%;
  margin-bottom: 1em;
}
.video-call-wrapper form > div > label {
  display: block;
  margin-bottom: 0.3em;
}
.video-call-wrapper form > div > input {
  display: block;
  width: 100%;
  font-size: 16px;
  padding: 0.4em;
  border-radius: 6px;
  border: 1px solid #333e5a;
}

.video-call-wrapper button {
  background: #333e5a;
  color: #fff;
  font-size: 16px;
  padding: 0.4em;
  border-radius: 6px;
  border: 1px solid transparent;
}
.video-call-wrapper button:hover {
  filter: brightness(150%);
}

.video-call-wrapper .room {
  position: relative;
  /* height: 100vh; */
}

.video-call-wrapper .room > h3 {
  text-align: center;
  font-weight: 300;
  margin-bottom: 1em;
}

.video-call-wrapper .local-participant {
  position: absolute;
  right: 2px;
  bottom: 90px;
  height: 200px;
  width: 200px;
  z-index: 9;
}
.video-call-wrapper .remote-participants {
  /* width: 100%;
  display: block; */
  height: 530px;
}
.video-call-wrapper .participant {
  background: #333e5a;
  border-radius: 6px;
  margin-right: 10px;
}
.video-call-wrapper .participant:last-child {
  margin-right: 0;
}
.video-call-wrapper .participant h3 {
  text-align: center;
  padding-bottom: 0.5em;
  color: #fff;
}

.video-call-wrapper video {
  /* display: block;
  margin: 0 auto;
  width: 60%;
  height: auto; */
  height: calc(100% + 2px);
  width: 100%;
  object-fit: cover;
  transform: scaleX(-1);
}

.video-call-wrapper .video-control-button-wrapper {
  padding: 20px;
  box-shadow: 0px 0px 10px -4px rgb(0 0 0 / 75%);
  -webkit-box-shadow: 0px 0px 10px -4px rgb(0 0 0 / 75%);
  -moz-box-shadow: 0px 0px 10px -4px rgb(0,0,0,0.75);
}

.video-call-wrapper .video-control-button-wrapper button {
  background-color: transparent;
  box-shadow: 0px 0px 10px -4px rgb(0 0 0 / 75%);
  -webkit-box-shadow: 0px 0px 10px -4px rgb(0 0 0 / 75%);
  -moz-box-shadow: 0px 0px 10px -4px rgb(0,0,0,0.75);
  color: black;
  font-size: 20px;
  margin: 0 10px;
  border-radius: 50%;
  width: 50px;
}

.video-call-wrapper .no-video-preview {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.video-call-wrapper .remote-muted {
  position: absolute;
  top: 20px;
  color: red;
  right: 20px;
  font-size: 20px;
}

.video-call-wrapper {
  max-width: 1000px;
  margin: 0 auto;
}

.video-call-wrapper .video-toggle,
.video-call-wrapper .leave-call {
  background-color: red !important;
  color: white !important;
}
