.kt-login__form {
    background-color: #fff;
    padding: 50px 25px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px -4px rgb(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 10px -4px rgb(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 10px -4px rgb(0,0,0,0.75);
}

.login-modal .login-button {
    padding: 20px 10px !important;
}

.login-modal .accept-checkbox-wrapper span {
    color: #185BDB !important;
    cursor: pointer;
}