.problem-area .background-image-category {
    width: 50px;
    height: 50px;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    margin: 0 auto;
    border-radius: 50%;
}
.problem-area .category-name {
    text-align: center;
    font-size: 12px;
    color: #000;
    font-weight: 600;
    padding-top: 5px;
    white-space: break-spaces;
    width: 100px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 15px;
    display: -webkit-box;
}

.booking-list .status-details-header {
    color: #185BDB;
    font-weight: 600;
    padding-bottom: 5px;
    font-size: 14px;
    font-family: 'Open Sans Regular';
}

.booking-list .cancel-info {
    color: #374561;
    font-weight: 400;
    padding-top: 5px;
    font-size: 13px;
    font-family: 'Open Sans Light';
}

.problem-area .categories-wrapper {
    padding: 15px;
    border-radius: 8px;
    width: 100%;
	overflow-x: auto;
	white-space: nowrap;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    /* background-color: #fff; */
}

.problem-area .categories-wrapper::-webkit-scrollbar {
    display: none;
}

.problem-area .custom-card {
    width: auto;
    display: inline-block;
    vertical-align: text-top;
    padding: 3px 5px;
    margin-right: 10px;
    box-shadow: 0px 0px 10px -4px rgb(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 10px -4px rgb(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 10px -4px rgb(0,0,0,0.75);
    border-radius: 8px;
    height: 50px;
    background-color: #fff;
}

.problem-area .next-button-wrapper {
    position: absolute;
    right: 15px;
    top: 30%;
    z-index: 99;
    height: 30px;
    width: 30px;
    background-color: #185BDB;
    border-radius: 50%;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: 60%;
    cursor: pointer;
    opacity: 0.7;
}

.problem-area .prev-button-wrapper {
    position: absolute;
    left: 15px;
    top: 30%;
    z-index: 99;
    height: 30px;
    background-color: #185BDB;
    border-radius: 50%;
    width: 30px;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: 60%;
    cursor: pointer;
    opacity: 0.7;
}

.problem-area .next-button-wrapper button,
.problem-area .prev-button-wrapper button {
    margin: 35px 0;
}

.problem-area .categories-heading {
    padding: 15px;
}

.scheduler .categories-heading {
    padding: 15px 5px;
}

.problem-area .professional-wrapper {
    padding: 0;
}

.problem-area .professional-wrapper .service-row {
    box-shadow: 0px 0px 10px -4px rgb(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 10px -4px rgb(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 10px -4px rgb(0,0,0,0.75);
    padding: 15px;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
}

.problem-area .professional-name {
    font-weight: 600;
    color: #000;
    font-size: 15px;
    margin-bottom: 5px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px !important;
}

.problem-area .professional-designation {
    font-size: 13px;
    color: #000;
    font-weight: 500;
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.problem-area .professional-designation img {
    float: right;
}

.problem-area .professional-location {
    font-size: 13px;
}

.problem-area .answer {
    font-weight: bold;
    font-size: 13px;
    cursor: default;
}

.problem-area .professional-exp {
    font-size: 11px;
    margin-top: 5px;
}

.problem-area .professional-exp span {
    float: right;
}

.problem-area .professional-exp img {
    margin-right: 3px;
    vertical-align: text-top;
    width: 14px;
}

.problem-area .background-image-professional,
.problem-area .background-image-premium {
    width: 50px;
    height: 50px;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    margin: 10px auto;
    border-radius: 50%;
}

.problem-area .whole-categories-wrapper {
    position: relative;
    padding: 0 15px;
}

.problem-area .sort-down {
    position: absolute;
    top: 20px;
    width: 20px;
    right: 25px;
    cursor: pointer;
}

.problem-area .language-dropdown-wrapper {
    padding: 15px;
}

.problem-area .language-dropdown-wrapper .btn-link {
    text-decoration: underline;
}

.problem-area .language-dropdown-wrapper .btn-link:hover {
    background-color: transparent;
    color: #5867dd;
}
.problem-area .language-dropdown-wrapper .dropdown {
    display: inline-block;
    margin-left: 10px;
}

.problem-area .dropdown-menu {
    right: 0;
}

.problem-area .professional-near-you-wrapper {
    padding: 15px;
    box-shadow: 0px 0px 10px -4px rgb(0 0 0 / 75%);
    -webkit-box-shadow: 0px 0px 10px -4px rgb(0 0 0 / 75%);
    -moz-box-shadow: 0px 0px 10px -4px rgb(0,0,0,0.75);
    background-color: #fff;
    border-radius: 8px;
}

.problem-area .select-day-custom-dropdown {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
}

.problem-area .form-check {
    display: inline-block;
    font-size: 14px;
    color: #000;
    font-weight: 400;
    margin-right: 10px;
}

.problem-area .read-out {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 8px;
    color: #000;
    margin-top: 10px;
}

.problem-area .faq-question i {
    float: right;
    font-size: 20px;
    position: absolute;
    top: 0;
    right: 0;
}

.problem-area .add-edit-service-button-wrapper .btn-primary {
    float: right;
    font-size: 13px;
}

.problem-area .add-edit-service-button-wrapper .reset-button {
    padding-left: 0;
    font-size: 13px;
}

.problem-area .add-edit-service-button-wrapper .reset-button:hover {
    background-color: #fff;
    color: #185BDB;
}

.problem-area .booking-details-header {
    border-bottom: 1px solid #ccc;
    color: #000;
    padding-bottom: 5px;
    font-size: 15px;
}

.problem-area .login-otp-input-wrapper {
    max-width: 200px;
    margin: 0 auto;
}

.problem-area .login-otp-input-wrapper .form-control {
    border: 1px solid #ccc;
    text-align: center;
    font-size: 16px;
    color: #185BDB;
    font-weight: 600;
}

.problem-area .complete-appointment-button {
    width: 200px;
}

.problem-area .fee-label {
    font-size: 14px;
}

.problem-area .fee-text {
    color: #185BDB;
    font-size: 14px;
}

.problem-area .fee-total-label {
    color: #000;
}
.problem-area .bor-top {
    border-top: 1px solid #ccc;
}
.problem-area .fee-total-wrapper {
    border-top: 1px solid #ccc;
    padding-top: 5px;
}

.problem-area .join-cancel-button-wrapper button {
    width: 150px;
}

.problem-area .faq-question {
    font-size: 13px;
    color: #000;
    font-weight: 600;
    padding-right: 20px;
}

.problem-area .customer-name {
    padding-right: 10px;
    position: relative;
}

.problem-area .w-200px {
    width: 200px;
}

.problem-area .customer-name i {
    position: absolute;
    top: 2px;
    right: 0;
}

.problem-area .bor-bot {
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.faq .category-name {
    padding-top: 8px;
}

.booking-list .professional-designation {
    color: #aaa;
}

.booking-list .professional-location {
    color: #000;
    font-size: 13px;
    font-weight: 600;
}

.booking-list .professional-exp {
    color: #000;
    font-size: 12px;
    font-weight: 600;
}
.booking-list .professional-time {
    color: #185BDB;
    font-size: 12px;
    font-weight: 600;
}

.booking-list .booking-profile-image {
    width: 80px;
    height: 80px;
    display: inline-block;
}

.booking-list .booking-profile-image .background-image-professional {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    margin: 0;
}

.booking-list .booking-details-wrapper {
    width: calc(100% - 80px);
    display: inline-block;
    padding-left: 10px;
    vertical-align: top;
}

.booking-list .nav-pills .nav-link {
    border: 1px solid #ccc;
    background-color: #fff;
    color: #5d78ff !important;
    padding: 10px 40px !important;
}

.booking-list .nav-pills .active {
    color: #fff !important;
}

.problem-area .ptb-5 {
    padding-top: 10px;
    padding-bottom: 5px;
}

.booking-list .upcoming-past-heading {
    padding-bottom: 15px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 15px;
    font-size: 16px;
    color: #185BDB;
}

.problem-area .problem-question {
    font-size: 20px;
    color: #000;
    margin-bottom: 5px;
}

.problem-area .problem-category {
    font-size: 14px;
    color: #aaa;
    margin-bottom: 15px;
    position: relative;
}

.problem-area .problem-answer {
    font-size: 16px;
    color: #000;
    margin-bottom: 15px;
    font-weight: 300;
}

.problem-area .problem-details-wrapper {
    margin: 0 auto;
    width: 600px;
    line-height: 30px;
}

.problem-area-details .fa-arrow-left {
    margin-right: 15px;
    cursor: pointer;
}

.problem-area .problem-category img {
    position: absolute;
    right: 5px;
    top: 0;
}

.problem-area .service-name {
    height: 40px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 20px;
}
.problem-area .service-cost {
    color: #185BDB;
    margin-top: 5px;
    font-weight: 600;
}

.problem-area .service-hour {
    color: #000;
    margin-top: 5px;
}

.problem-area .service-row img {
    position: absolute;
    right: -7px;
    top: -8px;
    cursor: pointer;
    width: 20px;
}

.problem-area .less-than-768 {
    display: none;
}

.problem-area .more-than-768 {
    display: block;
}

.problem-area .revenue-left-section {
    padding-left: 0;
    padding-right: 5px;
}

.problem-area .revenue-right-section {
    padding-right: 0;
    padding-left: 5px;
}

.problem-area td {
    word-break: break-all;
    font-weight: 400;
}

@media only screen and (min-width: 768px) {
    .problem-area .problem-more-768-pr-15-for-2 {
        padding-right: 15px;
    }
    .problem-area .problem-more-768-pb-15-for-2 {
        padding-bottom: 15px;
    }
}

@media only screen and (max-width: 767px) and (min-width: 575px) {
    .problem-area .problem-more-768-pr-15-for-2 {
        padding-right: 15px;
    }
    .problem-area .problem-more-768-pb-15-for-2 {
        padding-bottom: 15px;
    }
}
@media only screen and (max-width: 575px) {
    .problem-area .problem-more-768-pr-15 {
        padding-right: 0;
    }
    .problem-area .problem-more-768-pr-15-for-2 {
        padding-right: 0;
    }
    .problem-area .problem-more-768-pb-15-for-1 {
        padding-bottom: 15px;
    }
    .scheduler .holiday-button {
        padding: 6px 5px;
    }
    .booking-list .booking-profile-image {
        width: 40px;
        height: 40px;
    }
    .booking-list .booking-details-wrapper {
        width: calc(100% - 42px);
    }
    .problem-area .revenue-left-section {
        padding-left: 0;
        padding-right: 0;
    }
    .problem-area .revenue-right-section {
        padding-right: 0;
        padding-left: 0;
        margin-top: 10px;
    }
}

@media only screen and (max-width: 767px) {
    .problem-area .less-than-768 {
        display: block;
    }
    .problem-area .more-than-768 {
        display: none;
    }
    .problem-area .display-none {
        display: none;
    }
}