// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout Skins
@import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
@import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
@import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
@import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";

// Header Skins
.kt-header-base-light {
    @import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
}

.kt-header-base-dark {
    @import "./_metronic/_assets/sass/global/layout/header/skins/base/dark.scss";
}

// Header Menu Skins
.kt-header-menu-light {
    @import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
}
.kt-header-menu-dark {
    @import "./_metronic/_assets/sass/global/layout/header/skins/menu/dark.scss";
}

// Brand Skins
.kt-brand-dark {
  @import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
}

.kt-brand-light {
  @import "./_metronic/_assets/sass/global/layout/brand/skins/light.scss";
}

// Aside skins
.kt-aside-dark {
  @import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";
}

.kt-aside-light {
  @import "./_metronic/_assets/sass/global/layout/aside/skins/light.scss";
}

// Web Icon fonts
// @import "~socicon/css/socicon.css";
// @import "~@fortawesome/fontawesome-free/css/all.min.css";
// @import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
// @import "./_metronic/_assets/plugins/flaticon/flaticon.css";
// @import "./_metronic/_assets/plugins/flaticon2/flaticon.css";

@font-face {
  font-family: "Open Sans Regular";
  src: url("./fonts/OpenSans-Regular.ttf");
}

@font-face {
  font-family: "Open Sans Light";
  src: url("./fonts/OpenSans-Light.ttf");
}

@font-face {
  font-family: "Open Sans Semi Bold";
  src: url("./fonts/OpenSans-SemiBold.ttf");
}

@-webkit-keyframes fadeInUpContent {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUpContent {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUpContent {
  -webkit-animation-name: fadeInUpContent;
  animation-name: fadeInUpContent;
}

.box-shadow {
  box-shadow: 0px 0px 10px -4px rgba(253, 245, 245, 0.75);
  -webkit-box-shadow: 0px 0px 10px -4px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 10px -4px rgba(0,0,0,0.75);
  background-color: #fff;
}

#myAudio {
  width: 100%;
  margin-top: 10px;
}

#myAudio:focus {
  outline: none;
}

body {
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #fafafa;
  position: relative;
  color: #000;
}

.header-body-wrapper {
  flex: 1 0 auto;
}

// .spacer {
//   flex: 1;
// }

.footer {
  height: 60px;
  background-color: #ECF2FF;
  clear: both;
  min-height: 60px;
  width:100%;
  margin-top: auto;
  flex-shrink: 0;
  opacity: 0.7;
}

.footer .nav {
  padding: 10px 10px 10px 20px;
  display: inline-block;
}

.footer .nav li {
  display: inline-block;
}

.footer .copyright-text {
  color: #566986;
  font-family: 'Open Sans Regular';
  font-size: 12px;
  display: inline-block;
  text-align: right;
  padding: 10px 20px 10px 10px;
}

.footer .nav .nav-link {
  color: #2C3A4E !important;
  font-family: 'Open Sans Semi Bold';
}

.btn-primary {
  background-color: #185BDB !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.mb-15 {
  margin-bottom: 15px;
}

textarea {
  resize: none;
}

.pl-5 {
  padding-left: 5px !important;
}

h4 {
  font-family: 'Open Sans Semi Bold', sans-serif;
}

.professional-name,
.category-name,
.profile-name,
.problem-question,
.answer-commentedby,
.modal-header,
.question-input,
.faq-question,
.welcome-text,
.booking-details-header {
  font-family: 'Open Sans Semi Bold', sans-serif !important;
}

.professional-designation,
.professional-location,
.professional-exp,
.nav-link,
.profile-designation,
.profile-exp,
.profile-description-text,
.btn,
.service-name,
.service-cost,
.problem-category,
.problem-answer,
.read-out,
.answer-text,
.show-answers-bottom,
.question-info,
.professional-time,
.sign-in-text,
.form-check-label,
.booking-details-name,
.booking-details-description,
.booking-details-slot,
.fee-label,
.fee-text,
.profile-email,
.profile-website,
.service-hour,
.well {
  font-family: 'Open Sans Regular', sans-serif !important;
}

.color-black {
  color: #000;
}


::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}

.pt-10 {
  padding-top: 10px;
}
.pt-7 {
  padding-top: 7px;
}

#kt_login {
  background-color: #185BDB;
  display: table;
  height: 100vh;
}

#kt_login .whole-auth-wrapper {
  background-color: #185BDB;
  display: table-cell;
  vertical-align: middle;
}

#kt_login .kt-login__wrapper {
  background-color: #185BDB !important;
}

#kt_login .kt-login__body {
  margin-top: 0 !important;
}

#kt_login .kt-login__form {
  max-width: 350px;
}

.MuiFormControl-root {
  width: 100%;
  border: 1px solid #ccc !important;
  border-radius: 4px;
}

.MuiFormControl-root .MuiInput-underline:before {
  content: none;
}

.MuiFormControl-root .MuiInput-underline input {
  padding: 8px 13px;
}

.MuiInput-underline:after {
  border-bottom: none !important;
}

.span-link {
  float: right;
  color: #185BDB;
  font-size: 12px;
  margin-top: 5px;
  text-decoration: underline;
  cursor: pointer;
}

.nav-link, .dropdown-item {
  cursor: pointer;
}

.nav-link {
  color: #185BDB;
}

.input-error {
  color: #fd397a;
  font-size: 12px;
  margin-top: 5px;
}

.error-border {
  border-color: #fd397a !important;
}

button:disabled {
  cursor: default !important;
}

.btn-link:focus, .btn-link:active {
  background-color: transparent !important;
  color: #5d78ff !important;
}

.timepicker-error-border.MuiFormControl-root {
  border-color: #fd397a !important;
}

.pointer-none {
  pointer-events: none;
}

.react-calendar {
  width: 100%;
  line-height: 2em;
  font-size: 14px;
  box-shadow: 0px 0px 10px -4px rgba(0,0,0,0.75);
  -webkit-box-shadow: 0px 0px 10px -4px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 10px -4px rgba(0,0,0,0.75);
  background-color: #fff;
  border: 0;
  border-radius: 8px;
}

.w-48 {
  width: 48%;
}

.centered-for-max {
  max-width: 1200px !important;
  margin: 0 auto;
}

.react-calendar__tile--now,
.react-calendar__tile--now:enabled:hover, .react-calendar__tile--now:enabled:focus,
.react-calendar__tile--active,
.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
  background-color: transparent;
}

.react-calendar__tile--now abbr {
  background: #ffff76;
  border-radius: 50%;
  color: #000;
}

.react-calendar__tile--active abbr {
  padding: 7px;
  background: #185BDB;
  border-radius: 50%;
  color: #fff;
}

.react-calendar__tile--now abbr {
  padding: 7px 10px;
}

.font-12 {
  font-size: 12px;
}

.pac-container {
  z-index: 1000000 !important;
}

.p-15 {
  padding: 15px;
}

.custom-navigation-bar .nav-item.active .nav-link {
  color: #185CD9 !important;
  font-weight: bold;
}

.custom-navigation-bar .nav-item.active {
  border-bottom: 3px solid #185CD9;
}

.show-less-than-768 {
  display: none;
}

.show-more-than-768 {
  display: block;
}

@media only screen and (max-width: 767px) {
  .pad-0-below-768 {
    padding: 5px 0 !important;
  }
  .border-top-less-than-768 {
    border-top: 1px solid #ccc;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .border-top-less-than-768 .nav-link {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .margin-top-less-than-768 {
    margin-top: 10px;
  }
  .navbar-toggler {
    margin-right: 1rem;
  }
  .navbar-brand {
    margin-left: 1rem;
  }
  .footer {
    height: 90px;
  }
  .footer .nav {
    text-align: center;
    padding-bottom: 5px;
  }
  .footer .copyright-text {
    text-align: center;
    padding-top: 5px;
  }
  .show-less-than-768 {
    display: block;
  }
  .show-more-than-768 {
    display: none;
  }
}

@media only screen and (max-width: 440px) {
  .footer .nav .nav-link {
    padding: 5px;
  }
}

@media only screen and (max-width: 395px) {
  .footer .nav li {
    width: 50%;
  }
  .footer {
    height: 105px;
  }
}