.custom-navigation-bar {
    background-color: #ecf2ff;
}

.custom-navigation-bar .custom-left-navigation-bar {
    padding-bottom: 0;
    padding-top: 0;
    background-color: #ECF2FF;
}

.custom-left-nav .nav-link {
    padding-right: 25px !important;
}

.custom-navigation-bar .nav-link {
    color: #566986 !important;
    font-family: 'Open Sans Regular';
    padding: 20px 0.5rem;
}

.custom-navigation-bar .nav-item {
    padding: 0 5px;
}

.custom-right-navigation-bar {
    background-color: #fff;
    text-align: right;
    padding-right: 40px !important;
}

.dropdown-menu-opened {
    position: absolute !important;
    right: 0;
    left: auto !important;
}

.main-page-wrapper {
    max-width: 1000px !important;
    margin: 0 auto;
    padding: 15px 0;
}
.show-login-modal {
    display: block !important;
    background-color: rgba(0,0,0,0.5);
}

.login-modal .login-logo-wrapper {
    text-align: center;
}

.login-modal .welcome-text {
    color: #000;
    font-weight: 600;
    font-size: 16px;
}

.login-modal .sign-in-text {
    color: #aaa;
    font-size: 12px;
}

.login-modal .login-button {
    padding: 35px 10px;
    text-align: right;
}

.login-modal .login-button button {
    width: 100%;
    margin-bottom: 5px;
}
.login-modal .login-button .btn-link {
    font-size: 12px;
    color: #185BDB !important;
    text-decoration: underline !important;
    width: auto;
    padding-right: 0;
    padding-left: 0;
}
.login-modal .login-button .btn-link:hover {
    background-color: transparent;
    color: #185BDB;
}
.login-modal .login-phone-input {
    padding: 35px 10px 0;
}

.login-modal .login-phone-input .input-group {
    border: 1px solid #ccc;
    border-radius: 4px;
}

.login-modal .login-phone-input .input-group-text {
    color: #000;
}

.login-modal .accept-checkbox-wrapper {
    font-size: 11px;
    color: #242323;
}

.login-modal .accept-checkbox-wrapper .form-check-label {
    padding-left: 5px;
}

.login-modal .accept-checkbox-wrapper a {
    color: #185BDB !important;
}

.login-modal .modal-body {
    padding: 30px 15px;
}

.login-modal .login-otp-input-wrapper .form-control {
    border: 1px solid #ccc;
    text-align: center;
    font-size: 16px;
    color: #185BDB;
    font-weight: 600;
}

.login-modal .cancel-otp-page {
    float: left;
}

.ask-question-modal .login-button {
    padding: 25px 0 !important;
}

.ask-question-modal .question-input label {
    color: #000;
    font-size: 16px;
}

.ask-question-modal .modal-body {
    padding: 15px;
}

.ask-question-modal .modal-title {
    font-size: 18px !important;
}

.ask-question-modal .form-check {
    display: inline-block;
    font-size: 14px;
    color: #000;
    font-weight: 400;
    margin-right: 10px;
}

.ask-question-modal .question-input {
    margin-top: 15px;
}

.ask-question-modal .file-uploader {
    width: 40px;
    border: 1px solid #ccc;
    height: 40px;
    border-radius: 4px;
    text-align: center;
    margin-top: 10px;
    cursor: pointer;
}

.ask-question-modal .file-uploader i {
    font-size: 20px;
    vertical-align: middle;
    line-height: inherit;
}

.ask-question-modal .form-check-label {
    padding-left: 10px;
}

.nav-logo {
    width: 40px;
    height: 40px;
}

.custom-left-nav .nav-link {
    padding-right: 25px !important;
}

.navbar-nav .fa-bell {
    font-size: 18px;
    margin-right: 10px;
}
.profile-modal .profile-image-wrapper {
    height: 100px;
}
.profile-modal .profile-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    border-radius: 50%;
    width: 100px;
    margin: 0 auto;
    position: relative;
}

.profile-modal .profile-name {
    color: #000;
    font-size: 18px;
    font-weight: 600;
    margin-top: 10px;
}

.profile-modal .profile-designation {
    color: #185BDB;
    font-size: 14px;
    margin-top: 5px;
}

.profile-modal .profile-exp {
    color: #aaa;
    font-size: 14px;
    margin: 5px 0;
}

.profile-modal .file-uploader {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0, 0.7);
    border-radius: 50%;
    border: 2px solid #fff;
}

.profile-modal .file-uploader i {
    color: #fff;
    font-size: 16px;
    line-height: 35px;
    vertical-align: middle;
}

.profile-modal .accept-checkbox-wrapper {
    margin-top: 5px;
}