.dashboard .background-image-category {
    width: 50px;
    height: 50px;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    margin: 0 auto;
    border-radius: 50%;
}
.dashboard .category-name {
    text-align: center;
    font-size: 11px;
    color: #000;
    font-weight: 600;
    padding-top: 5px;
    white-space: break-spaces;
    width: 100px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 15px;
    display: -webkit-box;
}
.dashboard .example-text {
    font-size: 12px;
    margin-top: 5px;
}
.dashboard .categories-wrapper {
    padding: 15px;
    border-radius: 8px;
    width: 100%;
	overflow-x: auto;
	white-space: nowrap;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
}

.dashboard .custom-card {
    width: auto;
    display: inline-block;
    vertical-align: text-top;
    padding: 10px 5px;
    margin-right: 12px;
    box-shadow: 0px 0px 10px -4px rgb(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 10px -4px rgb(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 10px -4px rgb(0,0,0,0.75);
    border-radius: 8px;
    height: 100px;
    background-color: #fff;
}

.dashboard .next-button-wrapper {
    position: absolute;
    right: 15px;
    top: 30%;
    z-index: 99;
    height: 40px;
    width: 40px;
    background-color: #185BDB;
    border-radius: 50%;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: 70%;
    cursor: pointer;
    opacity: 0.7;
}

.dashboard .prev-button-wrapper {
    position: absolute;
    left: 15px;
    top: 30%;
    z-index: 99;
    height: 40px;
    background-color: #185BDB;
    border-radius: 50%;
    width: 40px;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: 70%;
    cursor: pointer;
    opacity: 0.7;
}

.dashboard .next-button-wrapper button,
.dashboard .prev-button-wrapper button {
    margin: 35px 0;
}

.dashboard .categories-heading {
    padding: 15px;
}

.dashboard .categories-heading i {
    margin-right: 15px;
    cursor: pointer;
}

.dashboard .professional-wrapper {
    padding: 0;
}

.dashboard .categories-wrapper::-webkit-scrollbar {
    display: none;
}

.dashboard .professional-wrapper .service-row {
    box-shadow: 0px 0px 10px -4px rgb(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 10px -4px rgb(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 10px -4px rgb(0,0,0,0.75);
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
}

.dashboard .professional-name {
    font-weight: 600;
    color: #000;
    font-size: 15px;
}

.dashboard .professional-designation {
    font-size: 13px;
}

.dashboard .professional-location {
    font-size: 13px;
}

.dashboard .professional-exp {
    font-size: 11px;
    margin-top: 5px;
}

.dashboard .professional-exp span {
    float: right;
}

.dashboard .professional-exp img {
    margin-right: 3px;
    vertical-align: text-top;
    width: 14px;
}

.dashboard .background-image-professional,
.dashboard .background-image-premium {
    width: 50px;
    height: 50px;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    margin: 10px auto;
    border-radius: 50%;
}

.dashboard .whole-categories-wrapper {
    position: relative;
    padding: 0 15px;
}

.dashboard .sort-down {
    position: absolute;
    top: 20px;
    width: 20px;
    right: 25px;
    cursor: pointer;
}

.dashboard .background-image-premium {
    width: 40px;
    height: 40px;
    margin: 0 auto;
}

.dashboard .premium-listing-wrapper {
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px -4px rgb(0 0 0 / 75%);
    -webkit-box-shadow: 0px 0px 10px -4px rgb(0 0 0 / 75%);
    -moz-box-shadow: 0px 0px 10px -4px rgb(0,0,0,0.75);
    height: 300px;
    overflow-y: auto;
    background-color: #fff;
    margin-top: 12px;
}

.dashboard .profile-image {
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    height: 120px;
    border-radius: 8px;
    width: 100%;
    cursor: pointer;
}

.dashboard .profile-name {
    color: #000;
    font-size: 18px;
    font-weight: 600;
}

.dashboard .profile-designation {
    color: #185BDB;
    font-size: 14px;
    margin-top: 5px;
}

.dashboard .profile-exp {
    color: #aaa;
    font-size: 14px;
}

.dashboard .profile-rating-wrapper {
    margin-top: 5px;
}

.dashboard .profile-rating-wrapper img {
    margin: 0 3px;
}

.dashboard .profile-rating-wrapper span {
    color: #185BDB;
    font-weight: 600;
    margin-right: 5px;
    font-size: 14px;
    vertical-align: sub;
}

.dashboard .profile-details-wrapper {
    box-shadow: 0px 0px 10px -4px rgb(0 0 0 / 75%);
    -webkit-box-shadow: 0px 0px 10px -4px rgb(0 0 0 / 75%);
    -moz-box-shadow: 0px 0px 10px -4px rgb(0,0,0,0.75);
    padding: 15px;
    border-radius: 8px;
    background-color: #fff;
}

.dashboard .profile-name-wrapper {
    padding-left: 15px;
    position: relative;
}

.dashboard .big-book-now-button {
    width: 100%;
    margin-top: 10px;
    padding: 4px;
    font-size: 12px;
    background-color: #185BDB;
}

.dashboard .follow-button {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 12px;
    padding: 4px 12px;
}

.dashboard .profile-menu-options {
    margin-top: 15px;
    margin-bottom: 15px;
}

.dashboard .about-label {
    font-weight: 600;
}

.dashboard .about-text {
    font-weight: 600;
    color: #185BDB;
}

.dashboard .description-image {
    height: 80px;
    margin: 5px;
}

.dashboard .service-cost {
    color: #185BDB;
    margin-top: 5px;
    font-weight: 600;
    font-size: 13px;
}

.dashboard .small-book-now-button {
    padding: 4px;
    font-size: 13px;
    background-color: #185BDB;
}

.dashboard .service-wrapper .row {
    cursor: default;
}

.dashboard .profile-email {
    color: #000;
    margin-top: 5px;
}

.dashboard .profile-website {
    color: #185BDB;
}

.dashboard .profile-image-wrapper {
    height: 100px;
}
.dashboard .profile-image-wrapper .profile-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    border-radius: 50%;
    width: 100px;
    margin: 0 auto;
    position: relative;
}

.dashboard .profile-image-wrapper .file-uploader {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0, 0.7);
    border-radius: 50%;
    border: 2px solid #fff;
    width: 30px;
    text-align: center;
}

.dashboard .profile-image-wrapper .file-uploader i {
    color: #fff;
    font-size: 16px;
    line-height: 30px;
    vertical-align: middle;
}

.dashboard .service-name {
    height: 40px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 20px;
}

.dashboard .professional-near-you-wrapper {
    padding: 15px;
    box-shadow: 0px 0px 10px -4px rgb(0 0 0 / 75%);
    -webkit-box-shadow: 0px 0px 10px -4px rgb(0 0 0 / 75%);
    -moz-box-shadow: 0px 0px 10px -4px rgb(0,0,0,0.75);
    background-color: #fff;
    border-radius: 8px;
}

.dashboard .booking-details-header {
    color: #000;
    font-weight: 600;
    padding-bottom: 5px;
    font-size: 15px;
}

.dashboard .booking-details-header div {
    height: 0.5px;
    background-color: #ccc;
    margin-top: 10px;
}

.dashboard .booking-details-name {
    font-size: 14px;
    font-weight: 600;
    padding: 5px 0;
}

.dashboard .booking-details-description {
    font-size: 14px;
    padding-bottom: 5px;
    color: #aaa;
}

.dashboard .booking-details-change-button {
    padding: 10px;
    text-align: center;
}

.dashboard .booking-details-change-button button {
    width: 50%;
    padding: 4px;
}

.dashboard .booking-details-slot {
    font-size: 14px;
    font-weight: 600;
    padding: 5px 0;
    color: #185BDB;
}

.box-shadow-wrapper {
    box-shadow: 0px 0px 10px -4px rgb(0 0 0 / 75%);
    -webkit-box-shadow: 0px 0px 10px -4px rgb(0 0 0 / 75%);
    -moz-box-shadow: 0px 0px 10px -4px rgb(0,0,0,0.75);
    padding: 10px;
    border-radius: 8px;
}

.dashboard .fee-label {
    font-weight: 600;
    font-size: 14px;
}

.dashboard .fee-text {
    font-weight: 600;
    color: #185BDB;
    font-size: 14px;
}

.dashboard .fee-total-label {
    color: #000;
}

.dashboard .fee-total-wrapper {
    border-top: 1px solid #ccc;
    padding-top: 5px;
}

.dashboard .appointment-number-details {
    font-size: 20px;
    letter-spacing: 10px;
    color: #185BDB;
}

.dashboard .appointment-details-button-wrapper button {
    width: 40%;
    margin-left: 5px;
    padding: 6px;
}

.dashboard .booking-profile-image {
    width: 80px;
    height: 80px;
    display: inline-block;
}

.dashboard .booking-profile-image .background-image-professional {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    margin: 0;
}

.dashboard .booking-details-wrapper {
    width: calc(100% - 80px);
    display: inline-block;
    padding-left: 10px;
}

.dashboard .profile-icon-wrapper i,
.dashboard .profile-icon-wrapper img {
    margin-right: 10px;
    cursor: pointer;
}

.dashboard .profile-icon-wrapper {
    margin-top: 5px;
}

.dashboard .profile-icon-wrapper i {
    color: #185BDB;
    font-size: 14px;
    vertical-align: middle;
}

.dashboard .profile-icon-wrapper img {
    width: 16px;
}


.ask-question-modal .login-logo-wrapper {
    text-align: center;
}

.ask-question-modal .welcome-text {
    color: #000;
    font-weight: 600;
    font-size: 16px;
}

.ask-question-modal .sign-in-text {
    color: #aaa;
    font-size: 12px;
}

.ask-question-modal .login-button {
    padding: 25px 0;
    text-align: right;
}

.ask-question-modal .login-button button {
    width: 100%;
    margin-bottom: 5px;
    font-size: 14px;
}
.ask-question-modal .login-button a {
    font-size: 12px;
    color: #185BDB !important;
    text-decoration: underline !important;
}
.ask-question-modal .login-phone-input {
    padding: 35px 10px 0;
}

.ask-question-modal .login-phone-input .input-group {
    border: 1px solid #ccc;
    border-radius: 4px;
}

.ask-question-modal .login-phone-input .input-group-text {
    color: #000;
}

.ask-question-modal .accept-checkbox-wrapper {
    font-size: 11px;
    color: #242323;
}

.ask-question-modal .accept-checkbox-wrapper .form-check-label {
    padding-left: 5px;
}

.ask-question-modal .accept-checkbox-wrapper a {
    color: #185BDB !important;
}

.ask-question-modal .modal-body {
    padding: 30px 15px;
}

.ask-question-modal .login-otp-input-wrapper .form-control {
    border: 1px solid #ccc;
    text-align: center;
    font-size: 16px;
    color: #185BDB;
    font-weight: 600;
}

.ask-question-modal .cancel-otp-page {
    float: left;
}

.ask-question-modal .question-info {
    margin-bottom: 15px;
    font-size: 13px;
}

.ask-question-modal .question-input label {
    color: #000;
    font-size: 16px;
}

.ask-question-modal .modal-body {
    padding: 15px;
}
 
.ask-question-modal .question-info i {
    margin-right: 10px;
}

.ask-question-modal .modal-title {
    font-size: 18px !important;
}

.review-modal .profile-rating-wrapper img {
    width: 25px;
}

.review-modal .review-label {
    color: #000;
    font-size: 14px;
    font-family: 'Open Sans Regular', sans-serif;
}

.review-modal .review-profile-details {
    border-bottom: 1px solid #ccc;
    padding: 5px 0 15px;
    margin: 0 0 15px 0;
}

.review-modal .background-image-premium {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 60px;
    border-radius: 8px;
    width: 100%;
}

.review-modal .profile-photo {
    width: 60px;
    display: inline-block;
}

.review-modal .profile-other-details {
    width: calc(100% - 110px);
    display: inline-block;
    padding-left: 15px;
}

.my-profile .profile-photo-wrapper {
    width: 120px;
    display: inline-block;
}

.my-profile .profile-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    border-radius: 8px;
    width: 100%;
}

.my-profile .profile-other-details {
    width: calc(100% - 130px);
    display: inline-block;
    padding-left: 15px;
}

.dashboard .profile-image-wrapper {
    width: 120px;
    display: inline-block;
}

.dashboard .profile-other-details {
    width: calc(100% - 130px);
    display: inline-block;
    padding-left: 15px;
}

.dashboard .about-image-wrapper {
    width: 100px;
    display: inline-block;
    margin-right: 15px;
    position: relative;
}

.dashboard .about-image-wrapper img {
    position: absolute;
    right: -15px;
    top: -3px;
    width: 20px;
    cursor: pointer;
}

.dashboard .service-hour {
    color: #000;
    margin-top: 5px;
}

.dashboard .form-check {
    display: inline-block;
    font-size: 14px;
    color: #000;
    font-weight: 400;
}

.dashboard .service-row img {
    position: absolute;
    right: -7px;
    top: -8px;
    cursor: pointer;
    width: 20px;
}

.dashboard .save-button {
    float: right;
}

.dashboard .custom-category-dropdown-wrapper {
    position: relative;
}

.dashboard .custom-category-dropdown-wrapper .custom-category-dropdown-select {
    padding: 5px 15px;
    border: 1px solid #e2e5ec;
    border-radius: 4px;
    cursor: pointer;
    color: #000;
}

.dashboard .custom-category-dropdown-wrapper .custom-category-dropdown-select i {
    float: right;
    font-size: 16px;
}

.dashboard .custom-category-dropdown-wrapper .row {
    margin: 0px;
    position: absolute;
    border: 1px solid #e2e5ec;
    padding: 5px;
    z-index: 99;
    background-color: #e2e5ec;
    max-height: 200px;
    overflow-y: auto;
}

@media only screen and (min-width: 768px) {
    .dashboard .problem-more-768-pr-15-for-2 {
        padding-right: 15px;
    }
    .dashboard .problem-more-768-pb-15-for-2 {
        padding-bottom: 15px;
    }
    .dashboard .right-padding {
        padding-right: 0;
    }
    .dashboard .left-padding {
        padding-left: 0;
    }
}

@media only screen and (max-width: 767px) and (min-width: 575px) {
    .dashboard .problem-more-768-pr-15-for-2 {
        padding-right: 15px;
    }
    .dashboard .problem-more-768-pb-15-for-2 {
        padding-bottom: 15px;
    }
}
@media only screen and (max-width: 575px) {
    .dashboard .problem-more-768-pr-15 {
        padding-right: 0;
    }
    .dashboard .problem-more-768-pr-15-for-2 {
        padding-right: 0;
    }
    .dashboard .problem-more-768-pb-15-for-1 {
        padding-bottom: 15px;
    }
}

@media only screen and (max-width: 767px) {
    .dashboard .margin-top-less-768 {
        margin-top: 15px;
    }
    .dashboard .right-padding {
        padding-right: 0;
        padding-left: 0;
    }
    .dashboard .left-padding {
        padding-left: 0;
        padding-right: 0;
    }
}

@media only screen and (max-width: 375px) {
    .dashboard .nav-tabs .nav-item .nav-link {
        padding: 0.75rem 6px;
    }
    .dashboard .example-text {
        font-size: 11px;
    }
}